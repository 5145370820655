import http from "@/utils/request.js";

/**
 * @methods 获取第一个下拉选项(产品名称)
 */
export const getNewDiscdetailsProOption = (params) => {
	return http({
		url: "/product/getUlList",
		method: "get",
		params,
	});
};

/**
 * @methods 获取第二个下拉选项(账户类型)
 */
export const getNewDiscdetailsAccOption = (params) => {
	return http({
		url: "/product/getUlFundList",
		method: "post",
		data:params
	});
};

/**
 * @methods 查询保险价格
 */
export const getNewDiscdetailsInquiry = (params) => {
	return http({
		url: "/product/getUlFundPriceByDate",
		method: "post",
		params,
	});
};

/**
 * @methods 导出保险价格
 */
export const getNewDiscdetailsExport = (params) => {
	return http({
		url: "/product/getUlFundPriceByDateExport",
		method: "post",
		data:params,
		responseType: "blob",
	});
};
