<template>
	<disclosureDetailsMobile  v-if="isMobile"></disclosureDetailsMobile>
	<div class="disclosureDetails" v-else>
		<crumbs :item="title" />
		<div class="tabs_box">
			<div class="tabs">
				<div
					class="tabs_son"
					v-for="(item, index) in list"
					:key="index"
					@click="cut(index)"
				>
					<div class="title_box" :class="{ blue: index == ins }">
						<p>{{ item }}</p>
					</div>
					<img
						:class="{ active: index == ins }"
						src="@/static/images/secondaryMenu/productsServices/line_check@2x.jpg"
						alt=""
					/>
				</div>
				<div class="line_box">
					<div class="line"></div>
				</div>
			</div>
		</div>
		<!-- 中文 -->
		<main v-show="ins == 0">
			<h2 class="announce">安联投资连结保险价格公告</h2>
			<div class="tabcontent">
				<el-form
					class="options"
					:model="ruleFormCN"
					:rules="rulesCN"
					ref="ruleFormCN"
					status-icon
				>
					<!-- 产品名称 -->
					<el-form-item class="product" prop="product">
						<span>产品名称:</span>
						<el-select
							v-model="ruleFormCN.product"
							placeholder="请选择"
							no-data-text="暂无数据"
							@change="getLabelCN"
						>
							<el-option
								v-for="item in prod_options_CN"
								:key="item.value"
								:label="item.label"
								:value="item.value"
								:disabled="item.disabled"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<!-- 账户类型 -->
					<el-form-item class="account" prop="account">
						<span>账户类型:</span>
						<el-select
							v-model="ruleFormCN.account"
							placeholder="请选择"
							no-data-text="暂无数据"
						>
							<el-option
								v-for="item in account_options_CN"
								:key="item.value"
								:label="item.label"
								:value="item.value"
								:disabled="item.disabled"
							>
							</el-option
						></el-select>
					</el-form-item>
					<!-- 日期 -->
					<div class="date">
						<!-- 开始日期 -->
						<el-form-item class="date-start" prop="startDate">
							<span class="demonstration">开始日期:</span>
							<el-date-picker
								v-model="ruleFormCN.startDate"
								type="date"
								placeholder="选择日期"
								:picker-options="startpickerOptionsCN"
								yyyy-MM-dd="yyyy 年 MM 月 dd 日"
								value-format="yyyy-MM-dd"
							>
							</el-date-picker>
						</el-form-item>
						<!-- 结束日期 -->
						<el-form-item class="date-end" prop="endDate">
							<span class="demonstration">结束日期:</span>
							<el-date-picker
								v-model="ruleFormCN.endDate"
								type="date"
								placeholder="选择日期"
								:picker-options="endpickerOptionsCN"
								yyyy-MM-dd="yyyy 年 MM 月 dd 日"
								value-format="yyyy-MM-dd"
							>
							</el-date-picker>
						</el-form-item>
					</div>
					<el-form-item class="bnt">
						<el-button
							class="inquire"
							plain
							@click="getNewDiscdetailsInquiry('ruleFormCN')"
							>查询</el-button
						>
						<el-button
							class="export"
							plain
							@click="getNewDiscdetailsExport('ruleFormCN')"
							>导出</el-button
						>
					</el-form-item>
				</el-form>
				<div class="tab">
					<vxe-table
					class="mytable-scrollbar"
						stripe
						border
						ref="xTable"
						max-height="380"
						:loading="loading"
						:row-config="{ isHover: true }"
						:data="tableDataCN"
					>
						<vxe-column type="seq" width="60"></vxe-column>
						<vxe-column field="date" title="计价日"></vxe-column>
						<!-- <vxe-column field="date" title="计价日" sort-type="Date" sortable></vxe-column> -->
						<vxe-column
							field="unit_value"
							title="投资单位价值"
							sort-type="number"
							sortable
						></vxe-column>
					</vxe-table>
				</div>
				<div class="tip">
					<p class="tip-text">
						以上表格显示的投资单位价格仅反映投资连接保险各投资账户的历史投资业绩，并不代表未来的投资收益。
						更多信息客户可以通过我们公司咨询电话：95342，当地分支机构或您的保险顾问查询。
					</p>
				</div>
			</div>
		</main>
		<!-- 英文 -->
		<main v-show="ins == 1">
			<h2 class="announce">
				Allianz&nbsp;Unit-linked&nbsp;product&nbsp;price&nbsp;announcement
			</h2>
			<div class="tabcontent">
				<el-form
					class="options"
					:model="ruleFormEN"
					:rules="rulesEN"
					ref="ruleFormEN"
					id="optionsEN"
					status-icon
				>
					<!-- 产品名称 -->
					<el-form-item class="product" prop="product">
						<span>Product&nbsp;Name:</span>
						<el-select
							v-model="ruleFormEN.product"
							placeholder="Please select"
							no-data-text="No data available!"
							@change="getLabelEN"
						>
							<el-option
								v-for="item in prod_options_EN"
								:key="item.value"
								:label="item.label"
								:value="item.value"
								:disabled="item.disabled"
							>
							</el-option
						></el-select>
					</el-form-item>
					<!-- 账户名称 -->
					<el-form-item class="account" prop="account">
						<span class="accountspan">Account&nbsp;Type:</span>
						<el-select
							v-model="ruleFormEN.account"
							placeholder="Please select"
							no-data-text="No data available!"
						>
							<el-option
								v-for="item in account_options_EN"
								:key="item.value"
								:label="item.label"
								:value="item.value"
								:disabled="item.disabled"
							>
							</el-option
						></el-select>
					</el-form-item>
					<!-- 日期 -->
					<div class="date">
						<el-form-item class="date-start" prop="startDate">
							<span class="demonstration" id="datespan">Start&nbsp;Date:</span>
							<el-date-picker
								v-model="ruleFormEN.startDate"
								type="date"
								placeholder="Select date"
								align="right"
								:picker-options="startpickerOptionsEN"
								yyyy-MM-dd="yyyy 年 MM 月 dd 日"
								value-format="yyyy-MM-dd"
							>
							</el-date-picker>
						</el-form-item>
						<el-form-item class="date-end" prop="endDate">
							<span class="demonstration">End&nbsp;Date:</span>
							<el-date-picker
								v-model="ruleFormEN.endDate"
								align="right"
								type="date"
								placeholder="Select date"
								:picker-options="endpickerOptionsEN"
								yyyy-MM-dd="yyyy 年 MM 月 dd 日"
								value-format="yyyy-MM-dd"
							>
							</el-date-picker>
						</el-form-item>
					</div>
					<!-- 查询和导出 -->
					<el-form-item class="bnt">
						<el-button
							class="inquire"
							id="inquirebtn"
							plain
							@click="getNewDiscdetailsInquiry('ruleFormEN')"
							>Inquiry</el-button
						>
						<el-button
							class="exportbtn"
							plain
							@click="getNewDiscdetailsExport('ruleFormEN')"
							>Export</el-button
						>
					</el-form-item>
				</el-form>
				<div class="tab">
					<vxe-table
					class="mytable-scrollbar"
						stripe
						border
						round
						ref="xTable"
						max-height="380"
						:loading="loading"
						:row-config="{ isHover: true }"
						:data="tableDataEN"
						:empty-render="{ name: 'NotData' }"
						empty-text="No data available!"
					>
						<vxe-column type="seq" width="60"></vxe-column>
						<vxe-column field="date" title="Valuation Date"></vxe-column>
						<vxe-column
							field="unit_value"
							title="Unit Price"
							sort-type="number"
							sortable
						></vxe-column>
					</vxe-table>
				</div>
				<div class="tip">
					<p class="tip-text">
						Unit Prices in above table only reflect the historical performance
						of UL investment accounts, it cannot be represented as the
						expectation for the future rate of return.For more information, you
						can call our hotline: 95342, contact our local branch or your
						insurance adviser.
					</p>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
	import crumbs from "@/components/crumbs.vue";
	import disclosureDetailsMobile from "./mobile/disclosureDetailsMobile.vue";
	import {
		getNewDiscdetailsInquiry,
		getNewDiscdetailsExport,
		getNewDiscdetailsProOption,
		getNewDiscdetailsAccOption,
	} from "@/api/newsnew/disclosuredetails";
	import langEN from "element-ui/lib/locale/lang/en";
	import langCN from "element-ui/lib/locale/lang/zh-CN";
	import locale from "element-ui/lib/locale";
	import common from '@/utils/common'

	export default {
		name: "disclosureDetails",
		components: {
			crumbs,
			disclosureDetailsMobile,
		},
		data() {
			return {
				isMobile:false,
				ins: 0,
				title: [
					{
						title: "新闻公告",
						link: "",
					},
					{
						title: "投连险信息披露",
						link: "/disclosure",
					},
					{
						title: "投连险信息披露-详情",
						link: "/disclosureDetails",
					},
				],
				list: ["中文", "English"],
				//产品名称
				prod_options_CN: [],
				prod_options_EN: [],
				//账户类型：
				account_options_CN: [],
				account_options_EN: [],
				//起始时间限制中文
				startpickerOptionsCN: {
					disabledDate: (time) => {
						if (this.ruleFormCN.endDate) {
							return (
								time.getTime() > new Date(this.ruleFormCN.endDate).getTime() ||
								time.getTime() > Date.now()
							);
						} else {
							return time.getTime() > Date.now();
						}
					},
				},
				//结束时间限制英文
				endpickerOptionsCN: {
					disabledDate: (time) => {
						if (this.ruleFormCN.startDate) {
							return (
								time.getTime() <
									new Date(this.ruleFormCN.startDate).getTime() ||
								time.getTime() > Date.now()
							);
						} else {
							return time.getTime() > Date.now();
						}
					},
				},
				//起始时间限制英文
				startpickerOptionsEN: {
					disabledDate: (time) => {
						if (this.ruleFormEN.endDate) {
							return (
								time.getTime() > new Date(this.ruleFormEN.endDate).getTime() ||
								time.getTime() > Date.now()
							);
						} else {
							return time.getTime() > Date.now();
						}
					},
				},
				//结束时间限制中文
				endpickerOptionsEN: {
					disabledDate: (time) => {
						if (this.ruleFormEN.startDate) {
							return (
								time.getTime() <
									new Date(this.ruleFormEN.startDate).getTime() ||
								time.getTime() > Date.now()
							);
						} else {
							return time.getTime() > Date.now();
						}
					},
				},
				//表格数据
				tableDataCN: [],
				tableDataEN: [],

				loading: false, //表格加载中
				labelCN: "", //第一个选项选中的中文下拉label值
				labelEN: "", //第一个选项选中的英文下拉label值
				coverCode: "", //上一页传过来的coverCode
				//------------选项选中值和表单验证规则---------------------
				ruleFormCN: {
					product: "", //选中的产品
					account: "", //选中的账户
					startDate: "", //选中的开始时间
					endDate: "", //选中的结束时间
				},
				ruleFormEN: {
					product: "", //选中的产品
					account: "", //选中的账户
					startDate: "", //选中的开始时间
					endDate: "", //选中的结束时间
				},
				rulesCN: {
					product: [
						{ required: true, message: "请选择产品名称", trigger: "change" },
					],
					account: [
						{ required: true, message: "请选账户类型", trigger: "change" },
					],
					startDate: [
						{ required: true, message: "请选择开始日期", trigger: "change" },
					],
					endDate: [
						{ required: true, message: "请选择结束日期", trigger: "change" },
					],
				},
				rulesEN: {
					product: [
						{
							required: true,
							message: "Please select a product name!",
							trigger: "change",
						},
					],
					account: [
						{
							required: true,
							message: "Please select an account type!",
							trigger: "change",
						},
					],
					startDate: [
						{
							required: true,
							message: "Please select a start date!",
							trigger: "change",
						},
					],
					endDate: [
						{
							required: true,
							message: "Please select an end date!",
							trigger: "change",
						},
					],
				},
			};
		},
		methods: {
			//切换中英文页面
			cut(index) {
				this.ins = index;
				const localeLang = index === 1 ? langEN : langCN;
				locale.use(localeLang);
				// this.ruleFormCN = {
				// 	product: "",
				// 	account: "",
				// 	startDate: "",
				// 	endDate: "",
				// };
				// this.ruleFormEN = {
				// 	product: "",
				// 	account: "",
				// 	startDate: "",
				// 	endDate: "",
				// };
				// this.tableDataCN = [];
				// this.tableDataEN = [];
			},

			//获取中英文第一个选项lable值
			getLabelCN(value) {
				const tempobjCN = this.prod_options_CN.find(
					(item) => item.value == value
				);
				this.labelCN = tempobjCN.label;
			},

			getLabelEN(value) {
				const tempobjEN = this.prod_options_EN.find(
					(item) => item.value == value
				);
				this.labelEN = tempobjEN.label;
			},

			//获取下拉选项数据
			async getNewDiscdetailsOption() {
				//获取第一个中英文选项
				{
					const [timeStamp, sign] = this.$getSign();
					const {
						data: { rows },
					} = await getNewDiscdetailsProOption({ timeStamp, sign });
					this.prod_options_CN = rows.map((item) => ({
						value: item.COVER_CODE,
						label: item.FULL_NAME,
					}));
					this.prod_options_EN = rows.map((item) => ({
						value: item.COVER_CODE,
						label: item.COVER_CODE_EN,
					}));
				}
				//获取第二个中英文选项
				{
					const [timeStamp, sign] = this.$getSign();
					const {
						data: { rows },
					} = await getNewDiscdetailsAccOption({
						timeStamp,
						sign,
						coverCode: this.coverCode, //上一页传过来的coverCode
					});
					this.account_options_CN = rows.map((item) => ({
						value: item.FUND_ID,
						label: item.FUND_FULL_NAME,
					}));
					this.account_options_EN = rows.map((item) => ({
						value: item.FUND_ID,
						label: item.FUND_FULL_NAME_EN,
					}));
				}
			},

			//获取表格数据
			async getNewDiscdetailsInquiry(formName) {
				this.loading = true;

				//校验
				const valid = await new Promise((resolve) => {
					this.$refs[formName].validate(resolve);
				});

				if (!valid) {
					console.log("error submit!!");
					this.loading = false;
					return false;
				}

				//发送请求
				const [timeStamp, sign] = this.$getSign();
				const params = {
					timeStamp,
					sign,
					coverCode: this[formName].product,
					fundId: this[formName].account,
					beginDate: this[formName].startDate.split("-").join(""),
					endDate: this[formName].endDate.split("-").join(""),
				};

				const { data } = await getNewDiscdetailsInquiry(params);

				//日期90天限制提示
				if (data.code == "500") {
					const warningMsg =
						formName === "ruleFormCN"
							? "日期超过90天，请重新选择!"
							: "The selected date exceeds 90 days. Please choose again!";
					this.$message.warning(warningMsg);
					this[formName === "ruleFormCN" ? "tableDataCN" : "tableDataEN"] = [];
					this.loading = false;
					return;
				}

				//没有数据提示
				if (data.data.length == "0") {
					const warningMsg =
						formName === "ruleFormCN"
							? "暂无数据，请重新选择!"
							: "No data available, please select again!";
					this.$message.warning(warningMsg);
					this[formName === "ruleFormCN" ? "tableDataCN" : "tableDataEN"] = [];
					this.loading = false;
					return;
				}

				//修改表格展示数据
				this.loading = false;
				this[formName === "ruleFormCN" ? "tableDataCN" : "tableDataEN"] =
					data.data.map((item, index) => ({
						id: index,
						date: item.KEYIN_DATE ? item.KEYIN_DATE.substring(0, 10) : "",
						unit_value: item.BID_PRICE ? item.BID_PRICE.toFixed(4) : "",
					}));
			},

			//点击导出
			async getNewDiscdetailsExport(formName) {
				//校验
				const valid = await new Promise((resolve) => {
					this.$refs[formName].validate(resolve);
				});

				if (!valid) {
					console.log("error submit!!");
					return false;
				}

				//发送签
				const [timeStamp, sign] = this.$getSign();
				const params = {
					timeStamp,
					sign,
					coverCode: this[formName].product,
					fundId: this[formName].account,
					beginDate: this[formName].startDate.split("-").join(""),
					endDate: this[formName].endDate.split("-").join(""),
				};

				// 导出之前，同时更新页面当前显示table数据
				this.getNewDiscdetailsInquiry(formName);
				const res = await getNewDiscdetailsExport(params);

				// 如果表格没有值，直接return结束
				if (
					(formName === "ruleFormCN" && this.tableDataCN.length === 0) ||
					(formName === "ruleFormEN" && this.tableDataEN.length === 0)
				) {
					return;
				}

				const objectUrl = URL.createObjectURL(
					new Blob([res.data], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					})
				);

				const link = document.createElement("a");
				//导出excel名字
				link.download =
					formName === "ruleFormCN"
						? `${this.labelCN}_${this.ruleFormCN.startDate}_${this.ruleFormCN.endDate}.xlsx`
						: `${this.labelEN}_${this.ruleFormEN.startDate}_${this.ruleFormEN.endDate}.xlsx`;
				link.style.display = "none";
				link.href = objectUrl;
				link.click();
				document.body.appendChild(link);
			},
		},

		async created() {
			const { countType } = this.$router.currentRoute.params;
			this.coverCode = countType
				? countType
				: localStorage.getItem("disclosureCovercode"); //从disclosure页面获取路由参数,请求第二个下拉选项
			this.isMobile = common.isMobile();
			
			var isPad = common.isPad();
			if(isPad){
				this.isMobile = false
			}
            console.log(this.isMobile);
            if (!this.isMobile) {
                await this.getNewDiscdetailsOption(); //获取下拉选项数据
            }
			// await this.getNewDiscdetailsOption(); //获取下拉选项数据
		},
	};
</script>

<style scoped lang="less">
.mytable-scrollbar ::-webkit-scrollbar {
	width: 5px ;
  } 
	.disclosureDetails {
		.tabs_box {
			width: 100%;
			border: 1px solid transparent;
			position: relative;
			height: 13rem;

			.tabs {
				//width: 60%;
				margin: 3rem auto 5rem;
				display: flex;
				flex-wrap: wrap;
				width: 65%;
				position: relative;
				//justify-content: space-between;
				//text-align: center;

				> .tabs_son {
					position: relative;
					margin: 0 1.5rem;
					display: flex;
					align-items: center;
					flex-direction: column;

					> .title_box {
						background-color: #dfeff2;
						padding: 1rem 1.8rem;
						font-size: 2rem;
						margin-bottom: 1rem;
						color: #003781;
						cursor: pointer;
						height: 6rem;
						align-items: center;
						justify-content: center;
						display: flex;
						width: 10rem;
						text-align: center;
					}

					.blue {
						color: #fff;
						background-color: #003781;
					}

					> img {
						width: 10rem;
						height: 3rem;
						//position: absolute;
						//bottom: -3.3px;
						z-index: 2;
						//left: 3.48rem;
						display: none;
						align-self: center;
					}
				}

				> .tabs_son:first-child {
					margin-left: 0;
				}

				.active {
					display: block !important;
				}

				//> li:nth-child(1) {
				//  img {
				//    display: block;
				//  }
				//}

				> li:nth-child(2) {
					img {
						left: 1.87rem;
					}
				}

				> li:nth-child(5) {
					img {
						left: 0.8rem;
					}
				}
			}

			.line_box {
				width: 100%;
				position: absolute;
				bottom: 0.8rem;

				.line {
					margin: auto;
					height: 0.25rem;
					background-color: #003781;
				}
			}
		}

		main {
			width: 80%;
			margin: 4rem auto;

			.iframe {
				height: 50rem;
				width: 607px;
				margin: 5rem auto;
			}
		}
	}

	// title公告
	.announce {
		text-align: center;
		font-size: 4rem;
		margin: 4rem 0;
		color: #003781;
	}

	//选项
	.tabcontent {
		width: 110rem;
		margin: 0 auto;
		padding: 4rem 0 2rem;
		background-color: #edeff5;

		.options {
			background-color: #f5f5f5;
			width: 100rem;
			margin: 0 auto;

			.product {
				padding: 10px 10px 0 40px;
				font-size: 1.8rem;

				//选项
				/deep/ .el-input__inner {
					width: 51.7rem;
					height: 3.5rem;
					line-height: 3.5rem;
				}

				//校验icon
				/deep/ .el-form-item__error {
					margin-left: 10rem;
				}

				//选项上下箭头
				// /deep/ .el-input__suffix {
				// 	.el-input__suffix-inner {
				// 		.el-input__icon {
				// 			line-height: 5rem;
				// 		}
				// 	}
				// }

				span {
					margin-right: 2rem;
				}
			}

			.account {
				padding: 0 10px 0 40px;
				font-size: 1.8rem;

				// 选项
				/deep/ .el-input__inner {
					width: 20.2rem;
					height: 3.5rem;
					line-height: 3.5rem;
				}

				//校验图标
				/deep/ .el-form-item__error {
					margin-left: 10rem;
				}

				//选项上下箭头
				// /deep/ .el-input__suffix {
				// 	.el-input__suffix-inner {
				// 		.el-input__icon {
				// 			line-height: 5rem;
				// 		}
				// 	}
				// }

				span {
					margin-right: 2rem;
				}

				.accountspan {
					margin-right: 2.7rem;
				}
			}

			.date {
				padding: 0 10px 0 40px;
				font-size: 1.8rem;
				display: flex;

				.date-start {
					margin-right: 2rem;

					span {
						margin-right: 2rem;
					}

					#datespan {
						margin-right: 5.2rem;
					}
					/deep/ .el-form-item__error {
						margin-left: 10rem;
					}

					/deep/ .el-date-editor {
						width: 20.3rem;

						//选择日期
						.el-input__inner {
							height: 3.5rem;
							line-height: 2rem;
						}

						//日期图标
						.el-input__icon {
							height: 4rem;
							line-height: 2rem;
						}
					}
				}

				.date-end {
					span {
						margin-right: 2rem;
					}
					/deep/ .el-form-item__error {
						margin-left: 10rem;
					}
					/deep/ .el-date-editor {
						width: 20rem;

						//选择日期
						.el-input__inner {
							height: 3.5rem;
							line-height: 2rem;
						}

						//日期图标
						.el-input__icon {
							height: 4rem;
							line-height: 2rem;
						}
					}
				}
			}

			.bnt {
				font-size: 1.8rem;

				.el-button {
					padding: 1.25rem 2rem;
					line-height: 1.5rem;
				}

				//中文按钮的距离
				.inquire {
					margin-left: 4.5rem;
					margin-bottom: 2rem;
				}

				.export {
					margin-left: 2.2rem;
				}

				//英文按钮的距离
				#inquirebtn {
					margin-left: 6rem;
					margin-bottom: 2rem;
				}

				.exportbtn {
					margin-left: 2.4rem;
				}
			}
		}

		//表格
		.tab {
			width: 100rem;
			margin: 2rem auto;
		}

		//tip
		.tip {
			background-color: #f2f2f2;
			padding: 1rem 4rem;
			border-radius: 5px;
			width: 100rem;
			margin: 2rem auto;
			font-size: small;
			text-align: justify;
		}

		//单独英文校验距离
		#optionsEN {
			/deep/ .el-form-item__error {
				margin-left: 14rem;
			}
			.date-end {
				/deep/ .el-form-item__error {
					margin-left: 10rem;
				}
			}
		}
	}
</style>
