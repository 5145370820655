<template>
    <div>
        <div class="mobile-disclosure">
            <header>
                <img src="@/static/images/ic_logo_bottom@2x.png" alt="" />
            </header>
            <crumbs :item="title"></crumbs>
            <div class="main-content">
                <div class="tabs_box">
                    <div class="tabs">
                        <div
                            class="tabs_son"
                            v-for="(item, index) in list"
                            :key="index"
                            @click="cut(index)"
                        >
                            <div class="title_box" :class="{ blue: index == ins }">
                                <p>{{ item }}</p>
                            </div>
                            <img
                                :class="{ active: index == ins }"
                                src="@/static/images/secondaryMenu/productsServices/line_check@2x.jpg"
                                alt=""
                            />
                        </div>
                        <div class="line_box">
                            <div class="line"></div>
                        </div>
                    </div>
                </div>
                <!-- 中文 -->
                <main v-show="ins == 0">
                    <h2 class="announce">安联投资连结保险价格公告</h2>
                    <div class="tabcontent">
						<div class="account">
							<van-cell-group>
								<van-field
									v-model="prod_options_CN_FLAG.value.text"
									label="产品名称："
									right-icon="arrow"
									placeholder="选择产品名称"
									readonly
									@click="showSelectPicker(prod_options_CN_FLAG)"
								/>
							</van-cell-group>
							<van-cell-group>
								<van-field
									v-model="account_options_CN_FLAG.value.text"
									label="账户类型："
									right-icon="arrow"
									placeholder="选择账户类型"
									readonly
									@click="showSelectPicker(account_options_CN_FLAG)"
								/>
							</van-cell-group>
							
						</div>
                        <el-form class="options" status-icon>
                            <!-- 日期 -->
                            <div class="date">
                                <!-- 开始日期 -->
                                <el-form-item class="date-start" prop="startDate">
                                    <span class="demonstration">开始日期：</span>
                                    <el-date-picker
                                        v-model="ruleFormCN.startDate"
                                        type="date"
                                        placeholder="选择日期"
                                        :picker-options="startpickerOptionsCN"
                                        yyyy-MM-dd="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <!-- 结束日期 -->
                                <el-form-item class="date-end" prop="endDate">
                                    <span class="demonstration">结束日期：</span>
                                    <el-date-picker
                                        v-model="ruleFormCN.endDate"
                                        type="date"
                                        placeholder="选择日期"
                                        :picker-options="endpickerOptionsCN"
                                        yyyy-MM-dd="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                            <el-form-item class="bnt">
                                <el-button
                                    class="inquire"
                                    plain
                                    @click="getNewDiscdetailsInquiry()"
                                    >查询</el-button
                                >
                                <el-button
                                    class="export"
                                    plain
                                    @click="getNewDiscdetailsExport()"
                                    >导出</el-button
                                >
                            </el-form-item>
                        </el-form>
                        <div class="tab">
                            <vxe-table
							class="mytable-scrollbar"
                                stripe
                                border
                                ref="xTable"
                                max-height="380"
                                :loading="loading"
                                :row-config="{ isHover: true }"
                                :data="tableDataCN"
                            >
                                <vxe-column type="seq" width="60"></vxe-column>
                                <vxe-column field="date" title="计价日"></vxe-column>
                                <!-- <vxe-column field="date" title="计价日" sort-type="Date" sortable></vxe-column> -->
                                <vxe-column
                                    field="unit_value"
                                    title="投资单位价值"
                                    sort-type="number"
                                    sortable
                                ></vxe-column>
                            </vxe-table>
                        </div>
                        <div class="tip">
                            <p class="tip-text">
                                以上表格显示的投资单位价格仅反映投资连接保险各投资账户的历史投资业绩，并不代表未来的投资收益。
                                更多信息客户可以通过我们公司咨询电话：95342，当地分支机构或您的保险顾问查询。
                            </p>
                        </div>
                    </div>
                </main>
                <!-- 英文 -->
                <main v-show="ins == 1">
                    <h2 class="announce">
                        Allianz&nbsp;Unit-linked&nbsp;product&nbsp;price&nbsp;announcement
                    </h2>
                    <div class="tabcontent">
						<div class="account">
							<van-cell-group>
								<van-field
									v-model="prod_options_EN_FLAG.value.text"
									label="Product Name:"
									right-icon="arrow"
									placeholder="Please Select Product Name"
									readonly
									@click="showSelectPicker(prod_options_EN_FLAG)"
								/>
							</van-cell-group>
							<van-cell-group>
								<van-field
									v-model="account_options_EN_FLAG.value.text"
									label="Account Type:"
									right-icon="arrow"
									placeholder="Please select"
									readonly
									@click="showSelectPicker(account_options_EN_FLAG)"
								/>
							</van-cell-group>
						</div>
                        <el-form class="options" status-icon>
                            <!-- 日期 -->
                            <div class="date">
                                <el-form-item class="date-start" prop="startDate">
                                    <span class="demonstration" id="datespan">Start&nbsp;Date:</span>
                                    <el-date-picker
                                        v-model="ruleFormEN.startDate"
                                        type="date"
                                        placeholder="Select date"
                                        align="right"
                                        :picker-options="startpickerOptionsEN"
                                        yyyy-MM-dd="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item class="date-end" prop="endDate">
                                    <span class="demonstration">End&nbsp;Date:</span>
                                    <el-date-picker
                                        v-model="ruleFormEN.endDate"
                                        align="right"
                                        type="date"
                                        placeholder="Select date"
                                        :picker-options="endpickerOptionsEN"
                                        yyyy-MM-dd="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                            <!-- 查询和导出 -->
                            <el-form-item class="bnt">
                                <el-button
                                    class="inquire"
                                    id="inquirebtn"
                                    plain
                                    @click="getNewDiscdetailsInquiry()"
                                    >Inquiry</el-button
                                >
                                <el-button
                                    class="exportbtn"
                                    plain
                                    @click="getNewDiscdetailsExport()"
                                    >Export</el-button
                                >
                            </el-form-item>
                        </el-form>
                        <div class="tab">
                            <vxe-table
							class="mytable-scrollbar"
                                stripe
                                border
                                round
                                ref="xTable"
                                max-height="380"
                                :loading="loading"
                                :row-config="{ isHover: true }"
                                :data="tableDataEN"
                                :empty-render="{ name: 'NotData' }"
                                empty-text="No data available!"
                            >
                                <vxe-column type="seq" width="60"></vxe-column>
                                <vxe-column field="date" title="Valuation Date"></vxe-column>
                                <vxe-column
                                    field="unit_value"
                                    title="Unit Price"
                                    sort-type="number"
                                    sortable
                                ></vxe-column>
                            </vxe-table>
                        </div>
                        <div class="tip">
                            <p class="tip-text">
                                Unit Prices in above table only reflect the historical performance
                                of UL investment accounts, it cannot be represented as the
                                expectation for the future rate of return.For more information, you
                                can call our hotline: 95342, contact our local branch or your
                                insurance adviser.
                            </p>
                        </div>
                    </div>
                </main>
            </div>
            <div class="bottom">
                <div class="qrcode">
                <div class="item">
                    <img src="~@/static/images/public.jpg" alt="" />
                    <span>公众号：安联人寿</span>
                </div>
                <div class="item">
                    <img src="~@/static/images/service.jpg" alt="" />
                    <span>服务号-安联在线</span>
                </div>
                </div>
                <div class="info">
                <p>© 安联人寿保险有限公司2012.All Rights Reserved.</p>
                <p class="beian">沪ICP备07011309号-1</p>
                </div>
            </div>
        </div>
		<!-- 选择 -->
		<selectPicker ref="selectPicker" @click="updataSelectPicker"></selectPicker>
    </div>
</template>

<script>
	import crumbs from "@/components/crumbs.vue";
	import selectPicker from '@/components/selectPicker.vue'
    import {
		getNewDiscdetailsInquiry,
		getNewDiscdetailsExport,
		getNewDiscdetailsProOption,
		getNewDiscdetailsAccOption,
	} from "@/api/newsnew/disclosuredetails";
	import langEN from "element-ui/lib/locale/lang/en";
	import langCN from "element-ui/lib/locale/lang/zh-CN";
	import locale from "element-ui/lib/locale";
	export default {
		name: "disclosureDetailsMobile",
        props: {
        },
		data() {
			return {
				ins: 0,
				title: [
					{
						title: "新闻公告",
						link: "",
					},
					{
						title: "投连险信息披露",
						link: "/disclosure",
					},
					{
						title: "投连险信息披露-详情",
						link: "/disclosureDetails",
					},
				],
				list: ["中文", "English"],
				//产品名称
				prod_options_CN_FLAG: {//选择信息
                    value: {
                        value: "",
                        text: ""
                    },
					type: 'prod_options_CN_FLAG',
                    data: [
                    ]
                },
				prod_options_EN_FLAG: {//选择信息
                    value: {
                        value: "",
                        text: ""
                    },
					type: 'prod_options_EN_FLAG',
                    data: [
                    ]
                },
				//账户类型：
				account_options_CN: [],
				account_options_EN: [],
				account_options_CN_FLAG: {//选择信息
                    value: {
                        value: "",
                        text: ""
                    },
					type: 'account_options_CN_FLAG',
                    data: [
                    ]
                },
				account_options_EN_FLAG: {//选择信息
                    value: {
                        value: "",
                        text: ""
                    },
					type: 'account_options_EN_FLAG',
                    data: [
                    ]
                },
				//起始时间限制中文
				startpickerOptionsCN: {
					disabledDate: (time) => {
						if (this.ruleFormCN.endDate) {
							return (
								time.getTime() > new Date(this.ruleFormCN.endDate).getTime() ||
								time.getTime() > Date.now()
							);
						} else {
							return time.getTime() > Date.now();
						}
					},
				},
				//结束时间限制英文
				endpickerOptionsCN: {
					disabledDate: (time) => {
						if (this.ruleFormCN.startDate) {
							return (
								time.getTime() <
									new Date(this.ruleFormCN.startDate).getTime() ||
								time.getTime() > Date.now()
							);
						} else {
							return time.getTime() > Date.now();
						}
					},
				},
				//起始时间限制英文
				startpickerOptionsEN: {
					disabledDate: (time) => {
						if (this.ruleFormEN.endDate) {
							return (
								time.getTime() > new Date(this.ruleFormEN.endDate).getTime() ||
								time.getTime() > Date.now()
							);
						} else {
							return time.getTime() > Date.now();
						}
					},
				},
				//结束时间限制中文
				endpickerOptionsEN: {
					disabledDate: (time) => {
						if (this.ruleFormEN.startDate) {
							return (
								time.getTime() <
									new Date(this.ruleFormEN.startDate).getTime() ||
								time.getTime() > Date.now()
							);
						} else {
							return time.getTime() > Date.now();
						}
					},
				},
				//表格数据
				tableDataCN: [],
				tableDataEN: [],

				loading: false, //表格加载中
				labelCN: "", //第一个选项选中的中文下拉label值
				labelEN: "", //第一个选项选中的英文下拉label值
				coverCode: "", //上一页传过来的coverCode
				//------------选项选中值和表单验证规则---------------------
				ruleFormCN: {
					startDate: "", //选中的开始时间
					endDate: "", //选中的结束时间
				},
				ruleFormEN: {
					startDate: "", //选中的开始时间
					endDate: "", //选中的结束时间
				},
			};
		},
		components: {
			crumbs,
			selectPicker,
		},
		methods: {
			//切换中英文页面
			cut(index) {
				this.ins = index;
				const localeLang = index === 1 ? langEN : langCN;
				locale.use(localeLang);
			},

			//获取中英文第一个选项lable值
			getLabelCN(value) {
				const tempobjCN = this.prod_options_CN_FLAG.data.find(
					(item) => item.value == value
				);
				this.labelCN = tempobjCN.label;
			},

			getLabelEN(value) {
				const tempobjEN = this.prod_options_EN_FLAG.data.find(
					(item) => item.value == value
				);
				this.labelEN = tempobjEN.label;
			},

			//获取下拉选项数据
			async getNewDiscdetailsOption() {
				//获取第一个中英文选项
				{
					const [timeStamp, sign] = this.$getSign();
					const {
						data: { rows },
					} = await getNewDiscdetailsProOption({ timeStamp, sign });
					var prod_options_CN = rows.map((item) => ({
						value: item.COVER_CODE,
						text: item.FULL_NAME,
					}));
					this.prod_options_CN_FLAG.data = prod_options_CN
					var prod_options_EN = rows.map((item) => ({
						value: item.COVER_CODE,
						text: item.COVER_CODE_EN,
					}));
					this.prod_options_EN_FLAG.data = prod_options_EN
				}
				//获取第二个中英文选项
				{
					const [timeStamp, sign] = this.$getSign();
					const {
						data: { rows },
					} = await getNewDiscdetailsAccOption({
						timeStamp,
						sign,
						coverCode: this.coverCode, //上一页传过来的coverCode
					});
					var account_options_CN = rows.map((item) => ({
						value: item.FUND_ID,
						text: item.FUND_FULL_NAME,
					}));
					this.account_options_CN_FLAG.data = account_options_CN
					var account_options_EN = rows.map((item) => ({
						value: item.FUND_ID,
						text: item.FUND_FULL_NAME_EN,
					}));
					this.account_options_EN_FLAG.data = account_options_EN
				}
			},

			//获取表格数据
			async getNewDiscdetailsInquiry() {
				if(this.ins === 0){
					if(this.prod_options_CN_FLAG.value.value === ''){
						return this.$toast('请选择产品名称!');
					}else if(this.account_options_CN_FLAG.value.value === ''){
						return this.$toast('请选账户类型!');
					}else if(!this.ruleFormCN.startDate){
						return this.$toast('请选择开始日期!');
					}else if(!this.ruleFormCN.endDate){
						return this.$toast('请选择结束日期!');
					}
				} else if(this.ins === 1){
					if(this.prod_options_EN_FLAG.value.value === ''){
						return this.$toast('Please select a product name!');
					}else if(this.account_options_EN_FLAG.value.value === ''){
						return this.$toast('Please select an account type!');
					}else if(!this.ruleFormEN.startDate){
						return this.$toast('Please select a start date!');
					}else if(!this.ruleFormEN.endDate){
						return this.$toast('Please select an end date!');
					}
				}
				this.loading = true;
				//发送请求
				const [timeStamp, sign] = this.$getSign();
				const params = {
					timeStamp,
					sign,
					coverCode: this.ins === 0 ? this.prod_options_CN_FLAG.value.value : this.prod_options_EN_FLAG.value.value,
					fundId: this.ins === 0 ? this.account_options_CN_FLAG.value.value : this.account_options_EN_FLAG.value.value,
					beginDate: this.ins === 0 ? this.ruleFormCN.startDate.split("-").join("") : this.ruleFormEN.startDate.split("-").join(""),
					endDate: this.ins === 0 ? this.ruleFormCN.endDate.split("-").join("") : this.ruleFormEN.endDate.split("-").join(""),
				};

				const { data } = await getNewDiscdetailsInquiry(params);

				//日期90天限制提示
				if (data.code == "500") {
					const warningMsg =
						this.ins === 0
							? "日期超过90天，请重新选择!"
							: "The selected date exceeds 90 days. Please choose again!";
					this.$message.warning(warningMsg);
					this[this.ins === 0 ? "tableDataCN" : "tableDataEN"] = [];
					this.loading = false;
					return;
				}

				//没有数据提示
				if (data.data.length == "0") {
					const warningMsg =
						this.ins === 0
							? "暂无数据，请重新选择!"
							: "No data available, please select again!";
					this.$message.warning(warningMsg);
					this[this.ins === 0 ? "tableDataCN" : "tableDataEN"] = [];
					this.loading = false;
					return;
				}

				//修改表格展示数据
				this.loading = false;
				this[this.ins === 0 ? "tableDataCN" : "tableDataEN"] =
					data.data.map((item, index) => ({
						id: index,
						date: item.KEYIN_DATE ? item.KEYIN_DATE.substring(0, 10) : "",
						unit_value: item.BID_PRICE ? item.BID_PRICE.toFixed(4) : "",
					}));
			},
			showSelectPicker(params){
                console.log(params, 'showSelectPickershowSelectPicker');
                this.$refs.selectPicker.parentMsg({ struct: { ...params }, ins:this.ins });
            },
            updataSelectPicker(params) {
                console.log(params, 'paramsparams');
				if(params.struct.type === 'prod_options_CN_FLAG'){
					this.prod_options_CN_FLAG.value = params.struct.value
				} else if(params.struct.type === 'prod_options_EN_FLAG'){
					this.prod_options_EN_FLAG.value = params.struct.value
				} else if(params.struct.type === 'account_options_CN_FLAG'){
					this.account_options_CN_FLAG.value = params.struct.value
				} else if(params.struct.type === 'account_options_EN_FLAG'){
					this.account_options_EN_FLAG.value = params.struct.value
				}
            },
			//点击导出
			async getNewDiscdetailsExport() {
				if(this.ins === 0){
					if(this.prod_options_CN_FLAG.value.value === ''){
						return this.$toast('请选择产品名称!');
					}else if(this.account_options_CN_FLAG.value.value === ''){
						return this.$toast('请选账户类型!');
					}else if(!this.ruleFormCN.startDate){
						return this.$toast('请选择开始日期!');
					}else if(!this.ruleFormCN.endDate){
						return this.$toast('请选择结束日期!');
					}
				} else if(this.ins === 1){
					if(this.prod_options_EN_FLAG.value.value === ''){
						return this.$toast('Please select a product name!');
					}else if(this.account_options_EN_FLAG.value.value === ''){
						return this.$toast('Please select an account type!');
					}else if(!this.ruleFormEN.startDate){
						return this.$toast('Please select a start date!');
					}else if(!this.ruleFormEN.endDate){
						return this.$toast('Please select an end date!');
					}
				}
				this.loading = true;
				//发送签
				const [timeStamp, sign] = this.$getSign();
				const params = {
					timeStamp,
					sign,
					coverCode: this.ins === 0 ? this.prod_options_CN_FLAG.value.value : this.prod_options_EN_FLAG.value.value,
					fundId: this.ins === 0 ? this.account_options_CN_FLAG.value.value : this.account_options_EN_FLAG.value.value,
					beginDate: this.ins === 0 ? this.ruleFormCN.startDate.split("-").join("") : this.ruleFormEN.startDate.split("-").join(""),
					endDate: this.ins === 0 ? this.ruleFormCN.endDate.split("-").join("") : this.ruleFormEN.endDate.split("-").join(""),
				};

				// 导出之前，同时更新页面当前显示table数据
				this.getNewDiscdetailsInquiry();
				const res = await getNewDiscdetailsExport(params);

				// 如果表格没有值，直接return结束
				if (
					(this.ins === 0 && this.tableDataCN.length === 0) ||
					(this.ins === 1 && this.tableDataEN.length === 0)
				) {
					return;
				}

				const objectUrl = URL.createObjectURL(
					new Blob([res.data], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					})
				);

				const link = document.createElement("a");
				//导出excel名字
				link.download =
				this.ins === 0
						? `${this.labelCN}_${this.ruleFormCN.startDate}_${this.ruleFormCN.endDate}.xlsx`
						: `${this.labelEN}_${this.ruleFormEN.startDate}_${this.ruleFormEN.endDate}.xlsx`;
				link.style.display = "none";
				link.href = objectUrl;
				link.click();
				document.body.appendChild(link);
			},
		},

		async created() {
			const { countType } = this.$router.currentRoute.params;
			this.coverCode = countType
				? countType
				: localStorage.getItem("disclosureCovercode"); //从disclosure页面获取路由参数,请求第二个下拉选项
			await this.getNewDiscdetailsOption(); //获取下拉选项数据
		},
	};
</script>
<style lang='scss' scoped>
.mytable-scrollbar ::-webkit-scrollbar {
	width: 5px ;
  } 
    @function px2rem($px, $screen: 1920) {
        @return calc(100vw / (#{$screen} / 10) * #{$px} / 10);
    }

    @function px2vw($px) {
        @return calc(100vw * #{$px} / 1920)
    }
    .mobile-disclosure {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background-color: #fff;
        height: 100vh;
        width: 100vw;
        padding-bottom: px2rem(30, 375);

        header {
            width: 100%;
            height: px2rem(50, 375);
            display: flex;
            align-items: center;
            padding-left: px2rem(15, 375);
            img {
                width: px2rem(75, 375);
                height: px2rem(30, 375);
            }
        }
        .main-content {
            width: 100%;
            padding: px2rem(10, 375) px2rem(10, 375);
            padding-bottom: px2rem(120, 375);
            height: 100%;
            overflow-y: scroll;
            .tabs_box {
                width: 100%;
                border: 1px solid transparent;
                position: relative;
                height: px2rem(45, 375);

                .tabs {
                    //width: 60%;
                    margin: px2rem(10, 375) auto px2rem(0, 375);
                    display: flex;
                    flex-wrap: wrap;
                    /* width: 80%; */
                    position: relative;
                    //justify-content: space-between;
                    //text-align: center;

                    > .tabs_son {
                        position: relative;
                        margin: 0 px2rem(10, 375);
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        > .title_box {
                            background-color: #dfeff2;
                            padding:  px2rem(10, 375) px2rem(18, 375) ;
                            font-size: px2rem(18, 375);
                            margin-bottom: px2rem(10, 375);
                            color: #003781;
                            cursor: pointer;
                            height: px2rem(30, 375);
                            align-items: center;
                            justify-content: center;
                            display: flex;
                            /* width: 10rem; */
                            text-align: center;
                        }

                        .blue {
                            color: #fff;
                            background-color: #003781;
                        }

                        > img {
                            width: px2rem(39, 375);
                            height: px2rem(14, 375);
                            //position: absolute;
                            //bottom: -3.3px;
                            z-index: 2;
                            //left: 3.48rem;
                            display: none;
                            align-self: center;
                        }
                    }

                    > .tabs_son:first-child {
                        margin-left: 0;
                    }

                    .active {
                        display: block !important;
                    }

                    //> li:nth-child(1) {
                    //  img {
                    //    display: block;
                    //  }
                    //}

                    > li:nth-child(2) {
                        img {
                            left: 1.87rem;
                        }
                    }

                    > li:nth-child(5) {
                        img {
                            left: px2rem(5, 375);
                        }
                    }
                }

                .line_box {
                    width: 100%;
                    position: absolute;
                    bottom:  px2rem(5, 375);

                    .line {
                        margin: auto;
                        height: 0.25rem;
                        background-color: #003781;
                    }
                }
            }

            main {
                /* width: 80%; */
                margin: px2rem(35, 375) auto;
            }
        }
        .bottom {
            width: 100%;
            background-color: #fff;
            padding: px2rem(10, 375) 0;
            position: absolute;
            bottom: 0;
            z-index: 9999;
            .qrcode {
                display: flex;
                justify-content: space-evenly;
                margin: px2rem(30, 375) 0;
                display: none;

            .item {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                img {
                    width: px2rem(100, 375);
                    height: px2rem(100, 375);
                }
                span {
                    font-size: px2rem(13, 375);
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #666666;
                    }
                }
            }

            .info {
                p {
                    font-size: px2rem(13, 375);
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: center;

                    &.beian {
                        color: RGBA(38, 77, 139, 1);
                    }
                }
            }
        }
    }
    // title公告
	.announce {
		text-align: center;
		font-size: px2rem(16, 375);
		margin: px2rem(10, 375) 0;
		color: #003781;
	}

	//选项
	.tabcontent {
		/* width: 110rem; */
		margin: 0 auto;
		padding: 4rem 0 2rem;
		background-color: #edeff5;

		.account {
			padding: px2rem(0, 375) px2rem(10, 375) 0 px2rem(10, 375);
			font-size: px2rem(18, 375);
			background: #f5f5f5;
			/deep/ .van-cell-group {
				.van-cell {
					background-color: #f5f5f5;
				}
			}
			/deep/ .van-field__label {
				width: unset;
			}
			/deep/ .van-field__control {
				font-size: calc(100vw / (375 / 10) * 12 / 10);
			}
			/deep/ .van-cell {
				padding: px2rem(6, 375) px2rem(0, 375);
				font-size: px2rem(12, 375);
			}
			// 选项
			/deep/ .el-input__inner {
				width: px2rem(250, 375);
				height: px2rem(30, 375);
				line-height: px2rem(30, 375);
			}

			//校验图标
			/deep/ .el-form-item__error {
				margin-left: px2rem(65, 375);
			}
			span {
				margin-right: 2rem;
			}
		}
		.options {
			background-color: #f5f5f5;
			/* width: 100rem; */
			margin: 0 auto;
			.date {
                padding: px2rem(6, 375) px2rem(12, 375);
				font-size: px2rem(12, 375);
				color: #646566;
				display: flex;
				height: px2rem(50, 375);
				.date-start {
					margin-bottom: 6px;
					height: px2rem(40, 375);

					span {
						margin-right: px2rem(6, 375);
					}

					#datespan {
						margin-right: 5.2rem;
					}
					/deep/ .el-form-item__error {
                        margin-left: px2rem(65, 375);
					}
					/deep/ .demonstration {
						font-size: px2rem(12, 375);
					}
					/deep/ .el-date-editor {
						width: px2rem(100, 375);

						//选择日期
						.el-input__inner {
                            width: px2rem(90, 375);
                            height: px2rem(30, 375);
                            line-height: px2rem(30, 375);
							padding: 0;
							padding-left: 5px;
							background-color: #f5f5f5;
						}
						.el-input__prefix {
							display: none;
						}
						.el-input__suffix {
							display: none;
						}

						//日期图标
						.el-input__icon {
							height: 4rem;
							line-height: 2rem;
						}
					}
				}

				.date-end {
					span {
						margin-right: 2rem;
					}
					/deep/ .el-form-item__error {
						margin-left: 10rem;
					}
					/deep/ .demonstration {
						font-size: px2rem(12, 375);;
					}
					/deep/ .el-date-editor {
						width: px2rem(100, 375);

						//选择日期
						.el-input__inner {
							width: px2rem(90, 375);
                            height: px2rem(30, 375);
                            line-height: px2rem(30, 375);
							padding: 0;
							padding-left: 5px;
							background-color: #f5f5f5;
						}

						.el-input__prefix {
							display: none;
						}
						.el-input__suffix {
							display: none;
						}
						//日期图标
						.el-input__icon {
							height: 4rem;
							line-height: 2rem;
						}
					}
				}
			}

			.bnt {
				font-size: px2rem(16, 375);

				.el-button {
					padding: px2rem(5, 375) px2rem(10, 375);
					line-height: px2rem(20, 375);
				}

				//中文按钮的距离
				.inquire {
					margin-left: px2rem(20, 375);
					margin-bottom: px2rem(20, 375);
				}

				.export {
					margin-left: px2rem(10, 375);
				}

				//英文按钮的距离
				#inquirebtn {
					margin-left: 6rem;
					margin-bottom: 2rem;
				}

				.exportbtn {
					margin-left: 2.4rem;
				}
			}
		}

		//表格
		.tab {
			/* width: 100rem; */
			margin: 2rem auto;
		}

		.tip {
			background-color: #f2f2f2;
			padding: px2rem(5, 375) px2rem(20, 375);
			border-radius: 5px;
			/* width: 100rem; */
			margin: px2rem(10, 375) auto;
			font-size: px2rem(12, 375);
			text-align: justify;
		}

		//单独英文校验距离
		#optionsEN {
			/deep/ .el-form-item__error {
				margin-left: 14rem;
			}
			.date-end {
				/deep/ .el-form-item__error {
					margin-left: 10rem;
				}
			}
		}
	}
</style>